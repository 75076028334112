.header {
  text-align: center;
  font-family: Popins;
  color: #ffffff99;
  margin-top: 2rem;
  > span:nth-of-type(2) {
    color: var(--app-primary-color);
    margin: 0 0.5rem;
    font-family: Montserrat;
  }
}
.main {
  width: 100%;
  display: flex;
  margin-top: 3rem;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: 3rem;
  > img {
    margin-right: 3rem;
    height: 4rem;
    width: 8rem;
    object-fit: inherit;
  }
}
@media screen and (max-width: 576px) {
  .header {
    margin-top: -2rem;
    font-size: 0.9rem;
  }
}

