.container {
  > h2 {
    color: var(--app-text-color) !important;
    text-align: center;
    margin: 5rem 0 1rem;
  }
  > .main {
    margin: 3rem 0 4rem 0;
    .collapseContent {
      :global {
        .next-antd-collapse-borderless {
          background-color: rgba(0, 0, 0, 0);
        }

        .next-antd-collapse-expand-icon,
        .next-antd-collapse-header-text {
          color: var(--app-text-color);
        }

        .next-antd-collapse-content-box {
          // color: var(--app-text-color);
          font-family: Poppins;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}

