/* ScrollingText.css */
@const: 472px;
.scroll-container {
  overflow: hidden; /* 隐藏超出容器的内容 */
  position: relative;
  width: 100%;
  height: @const;
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 25%,
    rgb(0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

.scroll-content {
  display: flex;
  flex-direction: column;
  animation: transTop 8s linear infinite;
}

.scroll-item {
  width: 100%;
  padding: 10px 0; /* 每个项目的内边距 */
  white-space: nowrap;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  align-items: center; /* 垂直居中 */
}

@keyframes transTop {
  15% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-@const);
  }
  40% {
    transform: translateY(-@const);
  }
  50% {
    transform: translateY(-2 * @const);
  }
  65% {
    transform: translateY(-2 * @const);
  }
  75% {
    transform: translateY(-3 * @const);
  }
  90% {
    transform: translateY(-3 * @const);
  }
  100% {
    transform: translateY(-4 * @const);
  }
}

