.container {
  > h2 {
    color: var(--app-text-color) !important;
    text-align: center;
    margin: 5rem 0;
  }
  .main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    aspect-ratio: 2 / 1;
    > div {
      //   border: 1px dashed rgba(255, 255, 255, 0.4);
      background-color: #121212;
      border-radius: 5px;
    }

    > div:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / 3;
      > div:nth-of-type(1) {
        font-weight: 500;
        padding: 2rem 0 0 2rem;
      }
      > div:nth-of-type(2) {
        padding-left: 2rem;
        font-family: Poppins;
        font-weight: bolder;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 1rem;
      }
    }
    > div:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      > div:nth-of-type(1) {
        font-weight: 500;
        padding: 2rem 0 0 2rem;
      }
      > div:nth-of-type(2) {
        width: 50%;
        padding-left: 2rem;
        > div:nth-of-type(1) {
          font-weight: 500;
          margin: 2rem 0 0 2rem;
        }
        font-family: Poppins;
        font-weight: bolder;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 1rem;
      }
    }
    > div:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      > div:nth-of-type(1) {
        font-weight: 500;
        padding: 2rem 0 0 2rem;
      }
      > div:nth-of-type(2) {
        padding-left: 2rem;
        font-family: Poppins;
        font-weight: 400;
        width: 50%;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 1rem;
      }
    }
  }
  .botBtn {
    margin: 4rem auto;
    width: 13rem;
    background: linear-gradient(180deg, #cefe00 0%, #fee600 100%);
    box-shadow: 0px 9px 16px 0px rgba(82, 139, 248, 0.2);
    text-align: center;
    height: 3rem;
    line-height: 3rem;
    border-radius: 1rem;
    color: #000000;
    cursor: pointer;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .main {
    gap: 0.8rem !important;
    > div:nth-child(1) {
      > div:nth-of-type(1) {
        padding: 1rem 0 0 1rem !important;
      }
      > div:nth-of-type(2) {
        padding-left: 1rem !important;
        margin-top: 0.3rem !important;
      }
    }
    > div:nth-child(2) {
      > div:nth-of-type(1) {
        padding: 1rem 0 0 1rem !important;
      }
      > div:nth-of-type(2) {
        padding-left: 1rem !important;
        margin-top: 0.3rem !important;
      }
    }
    > div:nth-child(3) {
      > div:nth-of-type(1) {
        padding: 1rem 0 0 1rem !important;
      }
      > div:nth-of-type(2) {
        padding-left: 1rem !important;
        margin-top: 0.3rem !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    > h2 {
      margin: 3rem 0;
      line-height: 3rem;
    }
    .main {
      display: block;
      > div {
        margin-bottom: 2rem;
      }
      > div:nth-child(1) {
        height: 28rem;
      }
      > div:nth-child(2) {
        height: 20rem !important;
        background-size: contain;
        > div:nth-of-type(2) {
          width: 90%;
        }
      }
      > div:nth-child(3) {
        height: 20rem;
        background-size: contain;
        > div:nth-of-type(2) {
          width: 90%;
        }
      }
    }
  }
}

