.container {
  aspect-ratio: 1250 / 376;
  // width: 1250px;
  width: calc(100vw - 6%);

  // height: 376px;
  margin: 0 3%;
  background-image: url("https://res-front.pumpsoul.com/prod-cobra/image/home_bottom_col.png");
  text-align: center;
  padding: 3rem 5rem;
  background-repeat: no-repeat;
  background-size: contain;
  > div:nth-of-type(1) {
    color: var(--app-text-color);
    width: 38rem;
    font-size: 1.4rem;
    margin: 2rem auto 4rem;
  }
  > div:nth-of-type(2) {
    height: 3rem;
    width: 15rem;
    line-height: 3rem;
    text-align: center;
    background: linear-gradient(180deg, #cefe00 0%, #fee600 100%);
    box-shadow: 0px 4px 12px 0px rgba(114, 92, 255, 0.2);
    border-radius: 1rem;
    margin: 2rem auto 2rem;
    cursor: pointer;
  }
}
@media (max-width: 1050px) and (min-width: 900px) {
  .container {
    padding: 1rem !important;
    div:nth-of-type(1) {
      margin: 0.5rem auto 1rem !important;
    }
  }
}
@media (max-width: 900px) and (min-width: 768px) {
  .container {
    padding: 1rem !important;
    div:nth-of-type(1) {
      margin: 0.5rem auto 1rem !important;
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .container {
    margin-top: -4rem !important;
    padding: 1rem !important;
    div:nth-of-type(1) {
      font-size: 1.2rem !important;
      width: 90% !important;
      margin: 0.5rem auto 0rem !important;
    }
    > div:nth-of-type(2) {
      margin: 1rem auto 1rem;
    }
  }
}
@media (min-width: 476px) and (max-width: 576px) {
  .container {
    margin-top: -3rem;
    background-image: url("https://res-front.pumpsoul.com/prod-cobra/image/home_bottom_col_mobile.png");
    padding: 1rem;
    aspect-ratio: 1 / 1.04;
    > div:nth-of-type(1) {
      color: var(--app-text-color);
      width: 80%;
      font-size: 2.2rem;
    }
    > div:nth-of-type(2) {
      width: 17rem;
    }
  }
}

@media screen and (max-width: 476px) {
  .container {
    margin-top: -3rem;
    background-image: url("https://res-front.pumpsoul.com/prod-cobra/image/home_bottom_col_mobile.png");
    padding: 1rem;
    aspect-ratio: 1 / 1.04;
    > div:nth-of-type(1) {
      color: var(--app-text-color);
      width: 80%;
      font-size: 1.2rem;
    }
    > div:nth-of-type(2) {
      width: 17rem;
    }
  }
}

