.topMianLogo {
  display: flex;
  > .left {
    flex: 0.55;
    > h1:nth-of-type(1) {
      color: #ffffff;
      font-size: 2.5rem;
      margin-top: 6rem;
      height: 4rem;
      min-width: 30rem;
    }
    > h1:nth-of-type(2) {
    }
    > .ipt {
      position: relative;
      margin-top: 3rem;
      width: fit-content;
      input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        font-weight: bolder;
        font-size: 0.8rem;
        font-family: Popins;
        font-weight: bolder;
      }
      .inputItem {
        height: 2.5rem;
        background: #151515;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        width: 22rem;
        color: #ffffff;

        :global {
          .next-antd-input {
            padding-left: 5px;
            color: #ffffff;
            background: #d9d9d9 !important;
            font-size: 0.8rem;
          }

          input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #d9d9d9 inset !important;
            box-shadow: 0 0 0 30px #151515 inset !important;
            -webkit-text-fill-color: #ffffff !important;
            text-fill-color: #ffffff !important;
          }
        }
      }
      > .btn {
        background-color: var(--app-primary-color);
        position: absolute;
        // left: 20rem;
        right: -3rem;
        top: 0px;
        color: #000009;
        height: 2.5rem;
        width: 7.5rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 10px;
        cursor: pointer;
        z-index: 9;
        border: none;
      }
    }
  }
  > .right {
    flex: 0.45;
    text-align: center;
    > .icon {
      width: 100%;
      // height: fit-content;
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .topMianLogo {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    > .left {
      > h1:nth-of-type(1) {
        font-size: 2.5rem;
        text-align: center;
        margin-top: 2rem;
        line-height: 4rem;
      }
      .ipt {
        margin: 2rem auto 0;
        .btn {
          // left: 21.5rem;
        }
      }
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .topMianLogo {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    > .left {
      > h1:nth-of-type(1) {
        font-size: 2.5rem;
        text-align: center;

        margin-top: 2rem;
        line-height: 4rem;
      }
      .ipt {
        margin: 2rem auto 0;
        .btn {
          width: 5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .topMianLogo {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    > .left {
      > h1:nth-of-type(1) {
        font-size: 2.5rem;
        text-align: center;
        min-width: auto !important;
        margin-top: 2rem;
        line-height: 3rem;
      }
      .ipt {
        margin: 0rem auto 0;
        > .btn {
          background-color: var(--app-primary-color);
          position: absolute;
          // left: 20rem;
          right: -0.5rem;
          top: 0px;
          color: #000009;
          height: 2.5rem;
          width: 5.5rem;
          text-align: center;
          line-height: 2rem;
          border-radius: 10px;
          cursor: pointer;
          z-index: 9;
          border: none;
        }
      }
    }
  }
  .inputItem {
    &::placeholder {
      font-size: 0.78rem !important;
    }
  }
}

