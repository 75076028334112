.container {
  text-align: center;
  > .header {
    font-size: 2rem;
  }
  > .header576 {
    font-size: 2rem;
  }
  > .desc {
    font-size: 1rem;
    font-family: Popins;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  > .mainImg {
    margin-bottom: 5rem;
    > img {
      width: 100%;
    }
  }
  > .howUseList {
    display: flex;
    margin-top: 3rem;
    position: relative;
    > div {
      flex: 1;
      overflow: hidden;
      > div {
        text-align: center;
      }
      > div:nth-of-type(1) {
        height: 12rem;
      }
      > div:nth-of-type(2) {
        margin-top: 1rem;
      }
      > div:nth-of-type(3) {
        margin-top: 0.5rem;
        width: 100%;
        font-family: Popins;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    > img {
      position: absolute;
      left: 18rem;
      top: 4rem;
    }
  }
}

@media screen and (max-width: 1460px) {
  .howUseList {
    > div {
    }
    > img {
      display: none;
    }
  }
}
@media (max-width: 900px) and (min-width: 768px) {
  .howUseList {
    > div {
      > div:nth-of-type(1) {
        height: 11rem !important;
      }
      > div:nth-of-type(2) {
        margin-top: 0rem !important;
      }
      > div:nth-of-type(3) {
        margin-top: 0.5rem;
        font-family: Popins;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
@media screen and(max-width: 576px) {
  .container {
    > .header {
      padding: 0 1rem;
      line-height: 2.5rem;
    }
    > .header576 {
      margin-top: 2rem;
    }
    .desc {
      padding: 0 2rem;
      margin-bottom: 2rem;
    }
    .howUseList {
      display: block;
      > div {
        margin-top: 1rem;
      }
    }
  }
}

