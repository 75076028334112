.container {
  max-width: 1300px;
  margin: auto;
  > h2 {
    color: var(--app-text-color) !important;
    text-align: center;
    margin: 5rem 0 1rem;
  }
  > .desc {
    font-size: 1rem;
    font-family: Popins;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 1rem;
    max-width: 550px;
    text-align: center;
    margin: 0 auto 4rem;
  }
  > .main {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    > .item {
      height: 25rem;
      background: #0b0b0b;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #444444;
      // aspect-ratio: 389 / 313;
      > div:nth-of-type(1) {
        img {
          width: 100%;
        }
      }
      > div:nth-of-type(2) {
        text-align: left;
        color: var(--app-text-color);
        padding-left: 2rem;
        margin: 1rem 0;
      }
      > div:nth-of-type(3) {
        display: flex;
        padding-left: 2rem;
        margin-bottom: 1rem;

        > div {
          margin-right: 1rem;
        }
      }
      > div:nth-of-type(4) {
        padding: 0 2rem;
        font-family: Popins;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .container {
    margin: 0 5rem;
    .main {
      grid-template-columns: repeat(2, 2fr);
      .item {
        height: 26rem;
      }
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .container {
    margin: 0 2rem;
    .main {
      grid-template-columns: repeat(2, 2fr);
      .item {
        height: 26rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    > h2 {
      margin: 2rem 0 1rem;
    }
    > .desc {
      margin-bottom: 2rem !important;
    }
    > .main {
      display: block;
      padding: 0 2rem;
      > .item {
        height: 30rem;
        margin-bottom: 1rem;
      }
    }
  }
}

