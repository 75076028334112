/* ScrollingText.css */
.scroll-container {
  height: 100px; /* 设置容器的高度 */
  overflow: hidden;
  width: 100%; /* 根据需要调整宽度 */
}

.scroll-content {
  display: flex;
  flex-direction: column;
  animation: transTop 8s linear infinite;
}

.scroll-item {
  padding: 10px 0; /* 每个项目的内边距 */
  height: 100px; /* 设置容器的高度 */
  display: flex;
  // overflow: hidden;
  align-items: center; /* 垂直居中 */
  background: linear-gradient(90.00000000000193deg, #cefe00 0%, #fee600 100%);
  font-size: 2.4rem;
  font-family: Caveat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// @keyframes transTop {
//   15% {
//     transform: translateY(0);
//   }
//   25% {
//     transform: translateY(-25%);
//   }
//   40% {
//     transform: translateY(-25%);
//   }
//   50% {
//     transform: translateY(-50%);
//   }
//   65% {
//     transform: translateY(-50%);
//   }
//   75% {
//     transform: translateY(-75%);
//   }
//   90% {
//     transform: translateY(-75%);
//   }
//   100% {
//     transform: translateY(-100%);
//   }
// }
@keyframes transTop {
  15% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-100px);
  }
  40% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(-200px);
  }
  65% {
    transform: translateY(-200px);
  }
  75% {
    transform: translateY(-300px);
  }
  90% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(-400px);
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .scroll-container {
    height: 100px;
  }
  .scroll-item {
    justify-content: center;
    height: 100px;
  }
}
@media (max-width: 769px) and (min-width: 576px) {
  .scroll-container {
  }
  .scroll-item {
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .scroll-container {
    margin-top: 2rem;
  }
  .scroll-item {
    font-size: 2rem;
    justify-content: center;
  }
}

